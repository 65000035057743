import { useFormik } from "formik";
import { DeckWithCollectionData } from "../logic";
import { updateDeck } from "../redux/decksReducer";
import { useDispatch } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Save } from "react-bootstrap-icons";
import * as Yup from "yup";

interface EditArchetypeFormProps {
  deck: DeckWithCollectionData;
  onSubmit?: () => void;
}

const EditArchetypeForm = ({ deck, onSubmit }: EditArchetypeFormProps) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { archetype: deck.archetype },
    onSubmit: (values) => {
      dispatch(updateDeck(deck._id, values));
      if (onSubmit) {
        onSubmit();
      }
    },
    validationSchema: Yup.object({
      archetype: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
    }),
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group>
        <Row className="align-items-center">
          <Col>
            <Form.Control
              type="text"
              name="archetype"
              onChange={formik.handleChange}
              value={formik.values.archetype}
              isInvalid={formik.errors.archetype ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.archetype}
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Button type="submit">
              <Save />
            </Button>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};

export default EditArchetypeForm;
