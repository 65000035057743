import * as React from "react";
import { Link } from "react-router-dom";
import Skeleton from "./Skeleton";
// import { PieChart, Pie, Cell } from "recharts";

interface MetagameSummaryProps {
  isLoading: boolean;
  metagameSummary: Array<{
    percentage: number;
    archetype: string;
    count: number;
  }>;
  format: string;
  showFooter?: boolean;
}

const MetagameSummary = ({
  isLoading,
  metagameSummary,
  format,
  showFooter = false,
}: MetagameSummaryProps) => {
  // const data = metagameSummary
  //   .slice(0, 9)
  //   .map((s) => ({
  //     name: s.archetype,
  //     value: Number((s.percentage * 100).toFixed(2)),
  //   }))
  //   .concat([
  //     {
  //       name: "Other",
  //       value: Number(
  //         Number(
  //           metagameSummary
  //             .slice(9)
  //             .map((x) => x.percentage)
  //             .reduce((a, i) => a + i, 0) * 100
  //         ).toFixed(2)
  //       ),
  //     },
  //   ]);

  return (
    <React.Fragment>
      {/* <PieChart
        width={400}
        height={300}
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
      >
        <Pie
          data={data}
          cx={120}
          cy={200}
          innerRadius={60}
          outerRadius={80}
          dataKey="value"
          label={({ name, value }) => `${name} (${value} %)`}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} />
          ))}
        </Pie>
      </PieChart> */}
      <h2>Metagame Summary</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Archetype</th>
            <th># of Decks</th>
            <th>% of Decks</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td>
                <Skeleton count={20} />
              </td>
              <td>
                <Skeleton count={20} />
              </td>
              <td>
                <Skeleton count={20} />
              </td>
            </tr>
          )}
          {!isLoading &&
            metagameSummary &&
            metagameSummary
              .sort((a, b) => b.percentage - a.percentage)
              .map((a, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/${format}/archetype/${a.archetype}`}>
                      {a.archetype}
                    </Link>
                  </td>
                  <td>{a.count}</td>
                  <td>{(a.percentage * 100).toFixed(2)}%</td>
                </tr>
              ))}
        </tbody>
        {showFooter && (
          <tfoot>
            {isLoading && (
              <tr>
                <th>
                  <Skeleton count={1} />
                </th>
                <th>
                  <Skeleton count={1} />
                </th>
                <th>
                  <Skeleton count={1} />
                </th>
              </tr>
            )}
            {!isLoading && metagameSummary && (
              <tr>
                <th>Total</th>
                <th>
                  {metagameSummary
                    .map((m) => m.count)
                    .reduce((a, b) => a + b, 0)}
                </th>
                <th>
                  {(
                    metagameSummary
                      .map((m) => m.percentage)
                      .reduce((a, b) => a + b, 0) * 100
                  ).toFixed(2)}
                  %
                </th>
              </tr>
            )}
          </tfoot>
        )}
      </table>
    </React.Fragment>
  );
};

export default MetagameSummary;
