import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import Deck from "./Components/Deck";
import CardDetails from "./Components/CardDetails";
import CardsNeeded from "./Components/CardsNeeded";

import { deckSelector } from "./redux/decksSelectors";
import { decksFeatureSelector, GlobalState } from "./redux/reducer";
import { loadDeck } from "./redux/decksReducer";
import { isLoadingCollectionSelector } from "./redux/collectionFeatureSelectors";
import { useAuth0 } from "@auth0/auth0-react";

const DeckPage = () => {
  const { isAuthenticated } = useAuth0();

  const dispatch = useDispatch();
  const params = useParams<{ id: string; format: string }>();

  const { isLoading } = useSelector(decksFeatureSelector);
  const isLoadingCollection = useSelector(isLoadingCollectionSelector);
  const deck = useSelector((state: GlobalState) => deckSelector(state, params));
  const [selectedCard, setSelectedCard] = React.useState<string>();

  React.useEffect(() => {
    if (params.id) {
      dispatch(loadDeck(params.id));
    }
    document.title = `MTG Results - Deck`;
  }, [dispatch, params.id]);

  React.useEffect(() => {
    document.title = `MTG Results - ${deck.archetype} by ${deck.owner}`;
  }, [deck]);

  return (
    <React.Fragment>
      <Row>
        <Col xl={2}>
          <Deck
            showLink={false}
            isLoading={isLoading}
            isLoadingCollection={isLoadingCollection}
            deck={deck}
            format={params.format || "modern"}
            selectCard={(c) => setSelectedCard(c)}
            isSignedIn={isAuthenticated}
          />
        </Col>
        <Col xl={5}>
          {!isAuthenticated && <p>Sign in to see what cards you need.</p>}
          {isAuthenticated && (
            <CardsNeeded
              isLoading={isLoading && isLoadingCollection}
              deck={deck}
            />
          )}
        </Col>
        {selectedCard && (
          <Col xl={5}>
            <CardDetails card={selectedCard} />
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default DeckPage;
